/* ==========================================================================
    CLEARFIX
   ========================================================================== */

// Clearfix Reloaded http://cssmojo.com/the-very-latest-clearfix-reloaded/
@mixin clearfix {
 &::after {
   clear: both;
   content: '';
   display: block;
 }
}
