/* ==========================================================================
     CODE
   ========================================================================== */

pre,
code,
kbd,
samp {
  font-family: $font-code;

  @if $opentype == true {
    // Turn on lining, tabular numerals, slashed zero
    font-feature-settings: "kern" 0, "liga" 0, "calt" 1, "dlig" 0, "pnum" 0, "tnum" 1, "onum" 0, "lnum" 1, "zero" 1;
  }
}

pre {
  @include box-shadow(0 0 0 1px $color-border inset, null);
  background-color: $color-background-input;
  border-radius: $radius-brand;
  display: inline-block;
  overflow: scroll;
  padding: spacer();
}
