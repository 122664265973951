/* ==========================================================================
    ALERT
   ========================================================================== */

.alert {
  @include box-shadow(0 0 0 1px transparentize($color-copy, .9) inset, 0 4px 15px transparentize($color-copy, .85));
  background: lighten($color-info, 30%);
  color: desaturate(darken($color-info, 25%), 25%);
  border-radius: $radius-brand;
  margin-top: spacer();
  max-width: $width-measure;
  padding: spacer();
  position: relative;

  a {
    color: desaturate(darken($color-info, 25%), 25%);

    &:focus,
    &:hover {
      color: $color-info;
    }
  }

  &__close {
    @include transition();
    background-color: transparent;
    border: 0;
    opacity: .5;
    padding: spacer($pico);
    position: absolute;
    right: .25em;
    top: 0;

    &::after {
      content: '\2715';
      display: block;
    }

    &:active,
    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  &__heading {
    color: currentColor;
  }

  // Contextual alert colours
  &--error {
    background: lighten($color-danger, 35%);
    color: desaturate(darken($color-danger, 25%), 25%);

    a {
      color: desaturate(darken($color-danger, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-danger;
      }
    }
  }

  &--success {
    background: lighten($color-success, 35%);
    color: desaturate(darken($color-success, 25%), 25%);

    a {
      color: desaturate(darken($color-success, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-success;
      }
    }
  }

  &--warning {
    background: lighten($color-warning, 35%);
    color: desaturate(darken($color-warning, 25%), 25%);

    a {
      color: desaturate(darken($color-warning, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-warning;
      }
    }
  }
}
