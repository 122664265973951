
/* ==========================================================================
    TEXT COLOUR CONTROL STYLES
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_c {
  color: $color-copy;

  &-current {
    color: currentColor !important;
  }

  // Contextutal colours
  &-danger {
    color: $color-danger !important;
  }

  &-info {
    color: $color-info !important;
  }

  &-success {
    color: $color-success !important;
  }

  &-warning {
    color: $color-warning !important;
  }
}

// Our brand palette colours
@if ($palettes) {
  @each $group, $colours in $palettes {
    @each $colour-name, $colour-value in $colours {

      .u_c-#{$group + '-' + $colour-name} {
        color: map-get( $colour-value, 'colour' ) !important;

        a {
          color: map-get( $colour-value, 'colour' ) !important;

          &:focus,
          &:hover {
            color: map-get( $colour-value, 'hover' ) !important;
          }
        }
      }

      a.u_c-#{$group + '-' + $colour-name} {
        &:focus,
        &:hover {
          color: map-get( $colour-value, 'hover' ) !important;
        }
      }
    }
  }
}
