
/* =============================================================================
    ASPECT RATIO
// scss-lint:disable ImportantRule
============================================================================= */

.u_aspect {
  
  &-square {
    padding-bottom: 100% !important;
  }

  &-4to3 {
    padding-bottom: 75% !important;
  }

  &-16to9 {
    padding-bottom: 56.25% !important;
  }

  &-21to9 {
    padding-bottom: 42.85% !important;
  }

  &-24to9 {
    padding-bottom: 37.5% !important;
  }

  &-27to9 {
    padding-bottom: 33.33% !important;
  }

  &-30to9 {
    padding-bottom: 30% !important;
  }
}
