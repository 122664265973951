/* ==========================================================================
    BOX SHADOW
   ========================================================================== */

// Better box-shadows.
@mixin box-shadow($shadow1: 0 1px 5px transparentize($color-body, .95), $shadow2: 0 1px 10px transparentize($color-body, .925), $shadow3: false) {
  $shadows: $shadow1;

  @if $shadow2 {
    $shadows: $shadow1, $shadow2;
  }

  @if $shadow3 {
    $shadows: $shadow1, $shadow2, $shadow3;
  }

  box-shadow: $shadows;
}
