/* ==========================================================================
  IMAGES
   ========================================================================== */

.img--bordered {
  border: 3px solid $color-border;
}

.img--circular {
  border-radius: 50%;
}

.img--contain {
  background-size: contain;
  max-height: 100%;
}

.img--cover {
  background-position: 50% 50%;
  background-size: cover;
}

.img--rounded {
  border-radius: $radius-brand;
}
