.download--pdf {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url('/assets/images/pdf_icon.png') no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 0.6rem;
}

.download--ebook {
  display: inline-block;
  width: 25px;
  height: 30px;
  background: url('/assets/images/ebook_icon.png') no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 0.6rem;
}
