/* ==========================================================================
    PAGE FORMS
   ========================================================================== */
.pages.type--pages.v--form {

  /* Form Labels
  ========================================================================== */
  .form__label {
    padding-bottom: spacer( $pico );
  }

  .form__label label {
    @include font-size( $root-size-em );
  }

  /* Form Rows
  ========================================================================== */
  .form__row {
    border-bottom: 1px solid $color-border;
    padding-bottom: spacer(  );

    &:last-child {
      border-bottom-width: 0;
    }
  }

}
