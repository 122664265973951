

// ==========================================================================
//  TYPE SETTING VODOO
// ==========================================================================
//
// The simplest method is to just pass the font-size and then get an automagically
// calculated lineheight and bottom margin to keep the element on the baseline.
// e.g.
//
// .foo {@include font-size( up( 1 ) );}
//
// compiles to:
//
// .foo {
//    font-size: 1.333em;
//    line-height: 1.5003750938;
//    margin-bottom: 1.5003750938em
// }
//
// Alternatively, you can pass a value for the top margin to the spacer
// function;
//
// .foo {@include font-size( up( 1 ), 2);}
//
// compiles to:
//
// .foo {
//    font-size: 1.333em;
//    line-height: 1.5003750938;
//    margin-bottom: .7501875469em; // half the default value
// }
//
// or omit the margin completely;
// .foo {@include font-size( up( 1 ), null);}
//
// compiles to:
//
// .foo {
//    font-size: 1.333em;
//    line-height: 1.5003750938;
// }
//
// Line-height can be specified explicitly, as an integer or valid line-height
// value, e.g.;
//
// .foo {@include font-size( up( 1 ), null, normal);}
//
// compiles to:
//
// .foo {
//    font-size: 1.333em;
//    line-height: normal;
// }
//

@mixin font-size($font-size: $root-size-em, $spacer: null, $lineheight: initial, $important: null) {

  // If you don't pass a lineheight param, auto-calculate it.
  @if $lineheight == auto {
    $lineheight-auto: ceil($font-size / $root-lineheight) * ($root-lineheight / $font-size);
    line-height: $lineheight-auto;
  }

  // If you pass an integer, or valid line-height setting we'll use that instead.
  @if (type-of($lineheight) == number or $lineheight == inherit or $lineheight == initial or $lineheight == normal) {
    line-height: $lineheight;
  }

  // Use the margin spacer value, if it's an integer.
  @if (type-of($spacer) == number) {
    margin-top: spacer($spacer, $font-size);
  }

  font-size: $font-size $important;
}
