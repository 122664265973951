/* ==========================================================================
    IMAGE
   ========================================================================== */

.img--author {
  width: 80px;
  border: $width-border solid white;
  box-shadow: 0 0 0 1px $color-border;
}

.img--company-logo {
  width: 80px !important;
}

.img--bordered {

  background-color: #cc9a61;
  border-color: $color-border opacify( $color-border, .075 ) opacify( $color-border, .125);
  border-radius: ( $radius-brand * .5 );
  border-width: spacer( );
  box-shadow: 0 -1px 0 0px rgba(255, 255, 255, 0.3), 0 0 0 1px rgba( 0, 0, 0, .5), 0 1px 3px 0px rgba( 0, 0, 0, .4);
  position: relative;

  &::after {
    background-color: gold;
    border-radius: $radius-brand;
    box-shadow: 0 1px 2px 1px rgba( 0, 0, 0, .1 );
    content: '';
    display: block;
    margin-left: - spacer( 1.25 );
    left: 50%;
    position: absolute;
    height: spacer( .7 );
    bottom: - spacer( .85 );
    width: spacer( 2.5 );
  }

  + figcaption {
    padding: 0 spacer( );
    font-style: italic;
    font-family: $font-serif;
  }
}

// OPTION B
/* ==========================================================================
    IMAGE
   ========================================================================== */
//
// .img--bordered {
//
//   background-color: #cc9a61;
//   border-color: $color-border opacify( $color-border, .075 ) opacify( $color-border, .075 ) $color-border;
//   border-radius: ( $radius-brand * .5 );
//   border-width: 1.5em;
//   position: relative;
//
//   &::before {
//     content: '';
//     display: block;
//     box-sizing: border-box;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     outline: 4px solid transparentize( palette( 'monochrome', 'darker' ), .75 );
//   }
//
//   &::after {
//     background-color: gold;
//     border-radius: $radius-brand;
//     box-shadow: 0 1px 2px 1px rgba( 0, 0, 0, .1 );
//     content: '';
//     display: block;
//     margin-left: - spacer( 1 );
//     left: 50%;
//     position: absolute;
//     height: spacer( .5 );
//     bottom: - spacer( .8 );
//     width: spacer( 2 );
//   }
//
//   + figcaption {
//     padding: 0 spacer( );
//     font-style: italic;
//     font-family: $font-serif;
//   }
// }
