.btn,
%btn {
  background-color: palette( 'blue', 'darker' );
  color: palette( 'monochrome', 'white' );
  font-weight: 400;
  letter-spacing: $letter-spacing;
  padding: spacer( $pico ) spacer( );
  text-transform: uppercase;

  &:active,
  &:focus,
  &:hover {
    background-color: palette( 'blue', 'dark' );
    color: palette( 'monochrome', 'white' );
  }
}
