/* =============================================================================
    BACKGROUND REPEAT
   ============================================================================= */
.u_bgr {
  background-repeat: repeat !important;

  &-norepeat {
    background-repeat: no-repeat !important;
  }

  &-x {
    background-repeat: repeat-x !important;
  }

  &-y {
    background-repeat: repeat-y !important;
  }

  &-round {
    background-repeat: round !important;
  }

  &-space {
    background-repeat: space !important;
  }

}
