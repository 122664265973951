/* ==========================================================================
    BODY
   ========================================================================== */

body {
  color: $color-copy;
  font: $weight-root #{$root-size-em}/#{$root-lineheight} $font-root;
  letter-spacing: 0.16px;

  // Did our webfonts load yet? Coolio, let's swap them in now, as long as we
  // are not in wireframe mode!
  .fonts-loaded & {
  //  font-family: $font-root;
  }

  @if $opentype == true {
    // Turn on kerning, standard ligatures, and proportional, oldstyle numerals
    // Turn off all other ligatures, tabular, lining numerals, and alternates
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0;
  }
}
