
/* ==========================================================================
    FONT STYLE
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_fs {

  &-italic {
    font-style: italic !important;
  }

  &-normal {
    font-style: normal !important;
  }
}
