.case-study-filter {
    border: solid 1px palette('monochrome', 'grey');
    background: palette('monochrome', 'white');
    outline: none;
    cursor: pointer;

    &.active {
        color: palette('monochrome', 'white');

        &::after {
            content: '×';
            margin-left: 0.5rem;
        }
    }
}

.case-study {

    &__hero {

        &__title {
          @include mq( $from: tablet ) {
              font-size: 2.90006em !important;
          }
        }

        &__container {
            align-items: stretch;
        }

        &__text-content {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            @media only screen and (min-width: 1400px) {
                padding-right: 3rem;
            }

        }

        &__image-content {
            height: 100%;

            img {
                object-fit: cover;
                height: 100%;
            }
        }


        &__categories {
            width: 100%;
        }

        &__meta {
            align-self: flex-end;
            width: 100%;

            &__item {
                margin-right: 1.5em;
                font-size: 0.9rem;

                @media only screen and (min-width: 1000px) {
                }

                @media only screen and (min-width: 1200px) {
                    margin-right: 3em;
                }
            }

            &__item:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__sidebar {
        @media only screen and (min-width: 1200px) {
            padding-right: 3.5rem !important;
        }
    }
}

.casestudies {

    &__cards {

        .cs-card {

            &__header {

                &__img {
                    display: block;
                }

                &__categories {


                    &__category{
                        transform: translateY(-50%);
                    }
                }


            }

            &__body {

                &__title {

                }

                &__intro {

                }

                a {
                    text-decoration: none;
                }
            }
        }

    }
}
