
/* ==========================================================================
    TEXT DECORATION
   ========================================================================== */

.u_td {
  text-decoration: none !important;

  &-linethrough {
    text-decoration: line-through !important;
  }

  &-underline {
    text-decoration: underline !important;
  }
}
