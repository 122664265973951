/* =============================================================================
    HEROTRON
============================================================================= */

.hero {
  background-position: 50% 100%;
  background-repeat: repeat-x;
  background-size: cover;
  position: relative;

  &__title {

    @include font-size( up( 6 ), null, 1 );

    @include mq($from: tablet) {
      @include font-size( up( 10 ), null, 1 );

      &__medium {
        @include font-size( up( 9 ), null, 1 );
      }

      &__small {
        @include font-size( up( 7 ), null, 1 );
      }
    }
  }

  &__subtitle {

    @include mq($from: tablet) {
      &__medium {
        @include font-size( up( 4 ), null, 1.1 );
      }

      &__small {
        @include font-size( up( 3 ), null, 1.1 );
      }
    }
  }

  &__summary {
    @include mq( $from: desktop ) {

    }
  }

  &--dark {

    &,
    h1,
    h2 {
      color: palette('monochrome', 'white');
    }
  }
}
