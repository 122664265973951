
/* ==========================================================================
    DISPLAY PROPERTIES
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_d {
  
  &-block {
    display: block !important;
  }

  &-flex {
    display: flex !important;
  }

  &-inline {
    display: inline !important;
  }

  &-iblock {
    display: inline-block !important;
  }

  &-none {
    display: none !important;
  }

  &-inherit {
    display: inherit !important;
  }
}
