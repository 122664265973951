/* ==========================================================================
    SHARED
   ========================================================================== */

/**
* Shared declarations for certain elements.
*/

/**
* Always declare margins in the same direction:
* csswizardry.com/2012/06/single-direction-margin-declarations
*/

/* stylelint-disable selector-list-comma-newline-after */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
// hr,
table,
fieldset {
  + * {
    margin-top: ($root-lineheight * 1rem);
  }
}
