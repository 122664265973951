/* ==========================================================================
    FORMS - SELECT STYES
   ========================================================================== */

.select {
  position: relative;

  // Undo the Firefox inner focus ring
  select:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-copy;
  }

  // Dropdown arrow
  &::after {
    position: absolute;
    top: 50%;
    right: spacer($pico);
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    pointer-events: none;
    border-top: spacer($pico) solid $color-primary;
    border-right: spacer($pico) solid transparent;
    border-bottom: spacer($pico) solid transparent;
    border-left: spacer($pico) solid transparent;
  }

  // Hide the arrow in IE10 and up
  select::-ms-expand {
    display: none;
  }

  // Media query to target Firefox only
  @-moz-document url-prefix() {
    // Firefox hack to hide the arrow
    select {
      text-indent: .01px;
      text-overflow: '';
      padding-right: 1em;
    }

    // <option> elements inherit styles from <select>, so reset them.
    option {
      background-color: $color-background;
    }
  }
}
