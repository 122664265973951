/* ==========================================================================
    SUPER AND SUBSCRIPTS
   ========================================================================== */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;

  @if $opentype == true {
    // Turn on supercript numerals
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0, 'sups' 1;
  }
}

sub {
  bottom: -.25em;

  @if $opentype == true {
    // Turn on subscript numerals
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0, 'subs' 1;
  }
}
