/* ==========================================================================
    TABLES
   ========================================================================== */


.table-inverted--blue {
  background-color: palette( 'blue', 'normal' );
  color: palette( 'monochrome', 'white' );

  td {
    border: 1px solid palette( 'monochrome', 'white' );
  }

  th {
    background-color: palette( 'monochrome', 'white' );
    border: 1px solid palette( 'blue', 'normal' );
    color: palette( 'blue', 'normal' );
  }
}
