.title-bar {
    background: #276bc9;
    text-align: center;
    color: white;
    padding: 0.5rem 1rem;
}

.title-bar a {
    color: white;
}
