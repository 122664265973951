/* ==========================================================================
    SITE LOGO
    ---

    <div class="logo overlay" itemscope itemtype="http://schema.org/Organization">
      <a class="overlay__link" itemprop="url" href="http://www.example.com/">Home</a>
      <img itemprop="logo" src="http://www.example.com/logo.png" />
    </div>
   ========================================================================== */

.logo__img {
  height: 100px;
  //padding: spacer( $pico );

  [class^="palette_bgc-blue"] &,
  [class*=" palette_bgc-blue"] &,
  [class^="u_bgc-monochrome-light"] &,
  [class*=" u_bgc-monochrome-light"] &,
  [class^="u_bgc-monochrome-dark"] &,
  [class*=" u_bgc-monochrome-dark"] &,
  [class^="u_bgc-blue"] &,
  [class*=" u_bgc-blue"] & {
    //background: palette( 'monochrome', 'white' );

    path ,
    #bottom,
    #top {

    }
  }
}
