/* ==========================================================================
    ARTICLE
   ========================================================================== */

.article-body {
  > .u_container {
    padding-left: 0;
    padding-right: 0;
  }

  > div.copy,
  > div.faqs {
    &:first-child {
       padding-top: 0 !important;
    }
  }
}
