/* =============================================================================
    BACKGROUND POSITION
   ============================================================================= */
.u_bgp {

  &-center {
    background-position: center center !important;
  }

  &-topleft {
    background-position: left top !important;
  }

  &-topright {
    background-position: right top !important;
  }

  &-topcenter {
    background-position: center top !important;
  }

  &-bottomleft {
    background-position: left bottom !important;
  }

  &-bottomright {
    background-position: right bottom !important;
  }

  &-bottomcenter {
    background-position: center bottom !important;
  }

  &-centerleft {
    background-position: left center !important;
  }

  &-centerright {
    background-position: right center !important;
  }

  &-centercenter {
    background-position: center center !important;
  }

}
