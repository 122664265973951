/* ==========================================================================
    SPACER
   ========================================================================== */

// Returns a context-dependant em value for spacing elements, where a
// value of 1 is equal to our vertical baseline height.
@function spacer($lines: 1, $font-size: $root-size-em) {
  @return (
    $lines * (strip-units($root-lineheight * $root-size-em))) / (strip-units($font-size)
  ) + em;
}
