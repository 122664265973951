/* ==========================================================================
    COPY
   ========================================================================== */

.copy {

  address,
  blockquote,
  h1, h2, h3, h4, h5, h6,
  p,
  dl, ol, ul,
  table {
    max-width: strip-units($width-measure) * 1rem;
  }

  &--lede p:first-of-type {
    @extend %lede;
  }

  ul,
  ol{
    li {
      padding-left: spacer($nano);
      margin-left: 1.5em;
    }
  }
}
