
/* =============================================================================
    POSITION
   ============================================================================= */

@mixin position($namespace: '') {

  $position-types:(absolute, relative, static, fixed, inherit, initial);

  @each $type in $position-types {
    .u_pos-#{$type} {
      position: $type !important;
    }
  }
}

@include position();

.u_pos-bottom { bottom: 0; }
.u_pos-left { left: 0; }
.u_pos-right { right: 0; }
.u_pos-top { top: 0; }
.u_pos-fill {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
