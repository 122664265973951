
/* ==========================================================================
    HEADINGS
    // scss-lint:disable SingleLinePerSelector
   ========================================================================== */

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $color-headings;
  font-family: $font-heading;
  font-weight: $weight-heading;

  > a {
    color: currentColor;
    text-decoration: inherit;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {

  @if $opentype == true {
    // Turn on discretionary ligatures for larger headings.
    font-feature-settings: 'kern' 1, 'liga' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 1;
  }
}

/*
* Size increments are set according to our chosen modular scale.
* Increments for h4 through h6 are zeroed out (1em) at smaller sizes
* to maintain legibility.
*/
h1, .h1 {
  @include font-size( up( 5 ) );

  @include mq($from: tablet) {
    @include font-size( up( 7 ) );
  }
}

h2, .h2 {
  @include font-size( up( 4 ) );

  @include mq($from: tablet) {
    @include font-size( up( 6 ) );
  }
}

h3, .h3 {
  @include font-size( up( 3 ) );

  @include mq($from: tablet) {
    @include font-size( up( 5 ) );
  }
}

h4, .h4 {
  @include font-size( up( 2 ) );

  @include mq($from: tablet) {
    @include font-size( up( 4 ) );
  }
}

h5, .h5 {
  @include font-size( up( 1 ) );

  @include mq($from: tablet) {
    @include font-size( up( 3 ) );
  }
}

h6, .h6 {
  @include font-size( down( 1 ) );

  @include mq($from: tablet) {
    @include font-size( $root-size-em );
  }
}
