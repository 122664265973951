
/* ==========================================================================
    OVERFLOW
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_o {
  overflow: auto !important;

  &-hidden {
   overflow: hidden !important;
  }

  &-scroll {
    overflow: scroll !important;
  }

  &-scroll-x {
    overflow-x: scroll !important;
  }

  &-scroll-y {
    overflow-y: scroll !important;
  }
}
