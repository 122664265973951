/* ==========================================================================
    FORM FIELD
   ========================================================================== */
.form__field {
  max-width: 20em;

  &--small {
    max-width: 40em;
  }

  &--large {
    max-width: 40em;
  }

  &--full {
    max-width: 100%;
  }

  .form__group--inline & {
    display: inline-block;
  }
}
