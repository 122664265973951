/* ==========================================================================
     FIGURES
   ========================================================================== */

figure {

  figcaption {
    @include font-size( down( 1 ), .5, initial );
    opacity: .7;
  }
}
