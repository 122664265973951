/* ==========================================================================
    MEASURE
   ========================================================================== */

/* Constrain line-lengths to optimise legibility. */
.u_measure {
  max-width: $width-measure;

  &-narrow {
    max-width: $width-measure-narrow;
  }

  &-middle {
      max-width: $width-measure-middle;
  }

  &-wide {
    max-width: $width-measure-wide;
  }
}
