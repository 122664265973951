/* ==========================================================================
  GUIDE
========================================================================== */

// Guide BG
// ====================
.guide__bg {
  bottom: spacer($micro);
  left: spacer($micro);
  right: spacer($micro);
  top: spacer($micro);
}

// Guide Image
// =================
.guide__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  bottom: spacer();
  left: spacer();
  right: spacer();
  top: spacer();
}

.guide--shownumber .guide__image {
  opacity: .5;
}

// Guide Copy
// =================
.guide__copy {
  height: 100%;
  min-height: 200px;
}

// Guide Number
// =================
.guide__number {
  left: spacer($pico);
  top: spacer($pico);
  width: spacer();
}