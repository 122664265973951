/* ==========================================================================
  BORDER RADIUS
   ========================================================================== */

.u_br {
  border-radius: $radius-brand !important;

  &-topleft {
    border-top-left-radius: $radius-brand !important;
  }

  &-topright {
    border-top-right-radius: $radius-brand !important;
  }

  &-bottomright {
    border-bottom-right-radius: $radius-brand !important;
  }

  &-bottomleft {
    border-bottom-left-radius: $radius-brand !important;
  }

  &-full {
    border-radius: 1000px;
  }
}
