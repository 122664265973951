/* ==========================================================================
    GRID
    ---
    Extension of RAGrid
   ========================================================================== */

$width-gutter-small: spacer(.25);
$width-gutter-large: spacer(1);

[grid] {

  &[padded] {
    margin: -#{$width-gutter};

    & > * {
      padding: $width-gutter;
    }
  }

  &[padded--large] {
    margin: -#{$width-gutter-large};

    & > * {
      padding: $width-gutter-large;
    }
  }

  &[padded--small] {
    margin: -#{$width-gutter-small};

    & > * {
      padding: $width-gutter-small;
    }
  }
}

[grid~="rows"] {
  flex-wrap: nowrap;
}
