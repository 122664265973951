/* ==========================================================================
    BUTTON STYLES
   ========================================================================== */
/*
Button styles can be applied to any element. Typically you'll want to
use either a `<button>` or an `<a>` element.
*/

.btn,
%btn {
  @include font-size($root-size-em, 0, null);
  @include transition();
  background-color: $color-background;
  border: 1px solid $color-border;
  border-radius: $radius-button;
  color: $color-copy;
  cursor: pointer;
  display: inline-block;
  font-family: $font-button;
  font-weight: $weight-button;
  padding: spacer($pico) spacer($milli);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-appearance: none;

  &:active,
  &:focus {

  }

  &:hover {

  }

  &.disabled,
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &--cta {
    background-color: $color-primary;

    &:hover {

    }
  }

  &--micro {
    @include font-size( down( 4 ), null, initial);
    padding: spacer($micro, down( 3 ) );
  }

  &--natural { // Full width buttons.
    width: 100%;
  }

  // Contextual alert colours
  &--danger {
    background-color: lighten($color-danger, 15%);
    color: desaturate(darken($color-danger, 25%), 25%);

    a {
      color: desaturate(darken($color-danger, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-danger;
      }
    }
  }

  &--notice {
    background-color: lighten($color-info, 10%);
    color: desaturate(darken($color-info, 25%), 25%);

    a {
      color: desaturate(darken($color-info, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-info;
      }
    }
  }

  &--safe {
    background-color: lighten($color-success, 15%);
    color: desaturate(darken($color-success, 25%), 25%);

    a {
      color: desaturate(darken($color-success, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-success;
      }
    }
  }

  &--warning {
    background-color: lighten($color-warning, 35%);
    color: desaturate(darken($color-warning, 25%), 25%);

    a {
      color: desaturate(darken($color-warning, 25%), 25%);

      &:focus,
      &:hover {
        color: $color-warning;
      }
    }
  }
}
