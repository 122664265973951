/* ==========================================================================
    FIGURE
   ========================================================================== */

.figure {

  &-left {
    margin-bottom: spacer($milli);
    @include mq($from: tablet) {
      float: left;
      margin-right: spacer();
      max-width: 50%;
    }
  }

  &-right {
    margin-bottom: spacer($milli);
    @include mq($from: tablet) {
      float: right;
      margin-left: spacer();
      max-width: 50%;
    }
  }

  &-full {
    margin-left: -#{spacer()};
    margin-right: -#{spacer()};

    figcaption {
      margin-left: spacer();
      margin-right: spacer();
    }
  }

  
}

div.figure + * {
  margin-top: ($root-lineheight * 1rem);
}
div.figure-full {
  text-align: center;

  img {
    display: inline-block;
  }
}
