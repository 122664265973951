/* ==========================================================================
    ABBREVIATIONS
   ========================================================================== */

abbr,
%abbr {

  @if $opentype == true {
    // Turn on small caps for upper and lowercase letters
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 0, 'lnum' 0, 'smcp' 1, 'c2sc' 1;
  }
}
