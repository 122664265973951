/* ==========================================================================
    WIREFRAME MODE
   ========================================================================== */

.wireframe {
  article,
  aside,
  footer,
  form,
  header,
  nav,
  section,
  .section {
    border: 1px solid $color-border !important;
    padding: spacer();
  }
}
