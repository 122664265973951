/* ==========================================================================
    CONTENT CONTAINER
   ========================================================================== */

.u_container {
  padding: spacer();
  margin-left: auto;
  margin-right: auto;
  max-width: $width-container;
}

.contain-fix {
  contain: none;
}
