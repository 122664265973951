/* ==========================================================================
    PERSON
   ========================================================================== */

.person {

  &__bordered {

  }

  [class*="icon-"],
  [class^=" icon-"] {
    fill: rgba( 0, 0, 0, .2 );
  }

  .card__content {
  //  padding: 0 spacer( );
  }

  + .person {
    margin-top: spacer( );
  }
}
