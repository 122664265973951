
/* ==========================================================================
    FONT FAMILY
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_ff {
  font-family: $font-root !important;

  &-sans {
    font-family: $font-sans !important;
  }

  &-serif {
    font-family: $font-serif !important;
  }

  &-system {
    font-family: $font-system !important;
  }

  &-code {
    font-family: $font-code !important;
  }
}
