/* ==========================================================================
    WHITESPACE
   ========================================================================== */

.u_ws {
  white-space: normal !important;

  &-nowrap {
    white-space: nowrap !important;
  }
}
