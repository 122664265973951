/* ==========================================================================
    SERVICES
   ========================================================================== */

.services {
  max-width: 1920px;

  &--alt {
    margin-top: - spacer( 3 );
    max-width: $width-container;
    padding: 0;
    border-bottom: 5px solid palette( 'green', 'light' );
    border-top: 5px solid palette( 'green', 'light' );
  }
}
