/* =============================================================================
    HIDE
    ---
    Allow hiding and showing of markup based on media queries
    usage
    <div hide-from="tablet">
      This will be hidden from tablet size upwards
    </div>
    <div hide-until="desktop">
      This div won't be shown until we get to the desktop breakpoint
    </div>
   ============================================================================= */

[hide] {
  display: none !important;
}

[hide-from~="mobile"] {
  @include mq($from: mobile) {
    display: none !important;
  }
}
[hide-from~="tablet"] {
  @include mq($from: tablet) {
    display: none !important;
  }
}
[hide-from~="desktop"] {
  @include mq($from: desktop) {
    display: none !important;
  }
}

[hide-until~="mobile"] {
  @include mq($until: mobile) {
    display: none !important;
  }
}

[hide-until~="tablet"] {
  @include mq($until: tablet) {
    display: none !important;
  }
}

[hide-until~="desktop"] {
  @include mq($until: desktop) {
    display: none !important;
  }
}
