/* ==========================================================================
    NAVIGATION
   ========================================================================== */

.nav {

  list-style: none;
  margin: 0;

  &.dark {

    li a {
      color: palette( 'monochrome', 'black' );
    }
  }

  li a {
    display: block;
    padding: spacer($nano) spacer($micro);
    text-decoration: none;
  }

  > li {

    > a:active,
    > a:hover,
    > a:focus {

    }

    &.active a {

    }

    > a {

    }
  }

  ul {
    list-style: none;
    margin: 0;
  }
}

.nav-primary {
  > li.has-children {
    position: relative;

    &:hover {
      ul {
        display: block;
      }
    }

    > a:focus + ul {
      display: block;
    }

    ul {
      background-color: palette(monochrome, 'white');
      color: palette(monochrome, 'black');
      display: none;
      list-style: none;
      margin: 0;
      position: absolute;
      z-index: 1;
    }

    li a {
      white-space: nowrap;
    }
  }
}
