/* ==========================================================================
    DOCUMENT ROOT
   ========================================================================== */

html {
  box-sizing: border-box;
  overflow-y: scroll;
  text-size-adjust: 100%;

  // @include mq( $from: wide ) {
  //   @include font-size( up( 1 ), null, auto );
  // }
}

/*
 * Border-box *all the things*, as suggested in:
 * http:// paulirish.com/2012/box-sizing-border-box-ftw
 */

* {

  + * {
  //  margin-top: spacer();
  }

  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
