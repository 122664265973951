
/* =============================================================================
    MARGIN TRUMPS
    // scss-lint:disable ImportantRule
============================================================================= */
$margin-sizes: (
  pico: spacer( $pico ),
  micro: spacer( $micro ),
  milli: spacer( $milli ),
  default: spacer( ),
  kilo: spacer( $kilo ),
  mega: spacer( $mega ),
  giga: spacer( $giga ),
);

.u_m {
  margin: spacer( ) !important;

  &-left {
    margin-left: spacer( ) !important;

    &-off {
      margin-left: 0 !important;
    }

    @each $size, $spacer in $margin-sizes {
      &-#{$size} {
        margin-left: $spacer !important;
      }
    }

  }

  &-left-auto {
    margin-left: auto !important;
  }

  &-right-auto {
    margin-right: auto !important;
  }

  &-horizontal-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &-vertical {
    margin-bottom: spacer() !important;
    margin-top: spacer() !important;

    &-auto {
      margin-bottom: auto !important;
      margin-top: auto !important;
    }

    &-off {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    @each $size, $spacer in $margin-sizes {
      &-#{$size} {
        margin-top: $spacer !important;
        margin-bottom: $spacer !important;
      }
    }
  }

  &-off {
    margin: 0 !important;
  }

  &-right {
    margin-right: spacer( );

    &-off {
      margin-right: 0 !important;
    }

    @each $size, $spacer in $margin-sizes {
      &-#{$size} {
        margin-right: $spacer !important;
      }
    }

  }

  &-bottom {
    margin-bottom: spacer( ) !important;

    @each $size, $spacer in $margin-sizes {
      &-#{$size} {
        margin-bottom: $spacer !important;
      }
    }

    &-off {
      margin-bottom: 0 !important;
    }

  }

  &-top {
    margin-top: spacer( ) !important;

    @each $size, $spacer in $margin-sizes {
      &-#{$size} {
        margin-top: $spacer !important;
      }
    }

    &-auto {
      margin-top: auto !important;
    }

    &-off {
      margin-top: 0 !important;
    }
  }
}
