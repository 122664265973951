/* ==========================================================================
    nav
    jshint
   ========================================================================== */
// scss-lint:disable SelectorDepth, NestingDepth

.nav-primary {

  li {

    &:hover {
      a {
        background-color: transparentize( palette( 'monochrome', 'white' ), .9 );
      }
    }

    a {
      border-radius: $radius-button;

      &:hover {
        background-color: transparentize( palette( 'monochrome', 'white' ), .9 );
      }
    }

  }

  > li {

    > a:focus,
    > a:hover,
    > a:active {
    }

    &.active a {}

    > a {
      color: palette( 'monochrome', 'white' );
      letter-spacing: $letter-spacing;
      margin: spacer( $pico ) 0;
    }
  }

  > .has-children {

    ul {
      @include font-size( down( 1 ) );
      border-radius: $radius-button;
      padding-bottom: spacer( $micro );
      padding-top: spacer( $micro );
    }

    li {


      a {
        color: palette( 'monochrome', 'darker' );
        padding: spacer( $pico ) spacer( $milli );

        &:hover {
          color: palette( 'blue', 'light' );
        }
      }
    }
  }
}

.none {
  .nav-primary {
    > li {

      &:hover {
        > a {
          background-color: transparentize( palette( 'monochrome', 'grey' ), .7 );
        }
      }

      > a {
        border-radius: $radius-button;
        color: palette( 'blue', 'normal' );

        &:hover {
          background-color: transparentize( palette( 'monochrome', 'grey' ), .7 );
        }
      }
    }
  }
}

.nav-secondary {

  > li > a {
    color: palette( 'monochrome', 'darker' );
    font-weight: 700;
  }

}

.nav-mobile {
  background-color: transparentize( palette( 'monochrome', 'lighter' ), .025 );
  display: none;
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  z-index: 50;

  &[data-toggled="true"] {
    display: block;
  }

  .nav ul {
    padding: 0 spacer( $micro ) spacer( $pico );

    a {
      color: palette( 'monochrome', 'darker' );

      &:hover {
        color: palette( 'monochrome', 'dark' );
      }
    }

  }

  .nav > li {
    border-bottom: 1px solid palette( 'monochrome', 'light' );

    > a {
      font-weight: 700;
      padding: spacer( $pico ) spacer( $micro );
    }
  }
}

.nav-mobile .nav-mobile__contact {
  ul:first-child {
    margin-top: 0;
  }

  a {
    text-decoration: none;
  }
}
