/* =============================================================================
  Template:   JBGass by WebDNA
  Authors:    Nathanial Hammond - @nfourtythree and Seth Warburton - @nternetinspired
  Version:    1.1
  Created:    April 2017
  Copyright:  WebDNA Ltd. - ©2017. All rights reserved
============================================================================= */

/* Override all the variable settings for the project here e.g.

  $color-primary: lemonochiffon;
 */
$font-sans: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-root: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-heading: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$radius-button: .25em;
$radius-brand: .25em;
$radius-input: $radius-button;

$palettes: (
  'monochrome': (
    'black': ( colour: #222626, hover: #585858 ),
    'darker':  ( colour: #585858, hover: #B7BFBF ),
    'dark':  ( colour: #B7BFBF, hover: #D2D7D7 ),
    'grey':  ( colour: #D2D7D7, hover: #758283 ),
    'light': ( colour: #E0E3E3, hover: #D2D7D7 ),
    'lighter': ( colour: #F3F3F3, hover: #D2D7D7 ),
    'lightest': ( colour: #F8F8F8, hover: #D2D7D7 ),
    'white': ( colour: #FFF, hover: #D2D7D7 )
  ),
  'blue': (
    'darkest':  ( colour: #060950, hover: #18206D ),
    'darker':   ( colour: #18206D, hover: #113195 ),
    'dark':     ( colour: #113195, hover: #0E4EAC ),
    'normal':   ( colour: #0E4EAC, hover: #2674D0 ),
    'light':    ( colour: #2674D0, hover: #52A1E8 ),
    'lighter':  ( colour: #52A1E8, hover: #85c6ff ),
    'lightest': ( colour: #85c6ff, hover: #52A1E8 ),
    'muted':    ( colour: #9BC9E0, hover: #9BC9E0 )
  ),
  'green': (
    'darkest':  ( colour: #004946, hover: #00625F ),
    'darker':   ( colour: #00625F, hover: #007C77 ),
    'dark':     ( colour: #007C77, hover: #009590 ),
    'normal':   ( colour: #009590, hover: #00AFA9 ),
    'light':    ( colour: #00AFA9, hover: #00C8C1 ),
    'lighter':  ( colour: #00C8C1, hover: #00E2DA ),
    'lightest': ( colour: #00E2DA, hover: #00C8C1 )
  ),
  'navy': (
    'darkest':  ( colour: #000249, hover: #000362 ),
    'darker':   ( colour: #000362, hover: #00047C ),
    'dark':     ( colour: #00047C, hover: #000595 ),
    'normal':   ( colour: #000595, hover: #0006AF ),
    'light':    ( colour: #0006AF, hover: #0007C8 ),
    'lighter':  ( colour: #0007C8, hover: #0008E2 ),
    'lightest': ( colour: #0008E2, hover: #0007C8 )
  )
);

$color-headings: inherit;
// $color-primary: palette(blue, normal);
$color-link: palette( 'blue', light );
$color-link--active: palette( 'blue', lighter );
$color-link--hover: $color-link--active;
$color-link--visited: $color-link--active;

$color-background-input: palette( monochrome, 'white' );

$scale: 1.1487;

$width-border: 6px;
$width-border-small: 3px;

// Turn off showing of media query breakpoints
$mq-show-breakpoints: ( );
