/* ==========================================================================
    LISTS
   ========================================================================== */

 /**
 * Consistent indentation for lists.
 */

dd,
ol,
ul {
  margin: spacer() 0 0 spacer();
}

dl > dt {
  font-weight: $weight-strong;
  margin-top: spacer();
}

/*
* There's a reason it's called 'hanging punctuation'and bullets should be outdented.
* See: http://www.markboulton.co.uk/journal/five-simple-steps-to-better-typography-part-2
*/
ul {
  list-style-position: outside;
}

ol {
  @if $font-root == $font-serif {
    list-style-type: lower-roman; // Have a little class!
  }
}


/**
 * Remove leading margins from nested lists.
 */

li > {

  ol,
  ul {
    margin-top: 0;
  }
}
