/* ==========================================================================
    TRIANGLE
   ========================================================================== */

// @include triangle within a pseudo element and add positioning properties
// (ie. top, left) $direction: up, down, left, right
@mixin triangle( $direction, $size: $root-size-em, $color: palette( monochrome, light ) ) {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;

  @if ( $direction == 'up' ) {
    border-bottom: ( 1.5 * $size ) solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  } @else if ( $direction == 'down' ) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: ( 1.5 * $size ) solid $color;
  } @else if ( $direction == 'left' ) {
    border-bottom: $size solid transparent;
    border-right: ( 1.5 * $size ) solid $color;
    border-top: $size solid transparent;
  } @else if ( $direction == 'right' ) {
    border-bottom: $size solid transparent;
    border-left: ( 1.5 * $size ) solid $color;
    border-top: $size solid transparent;
  }
}
