/* ==========================================================================
    PARAGRAPHS
   ========================================================================== */

p {
  orphans: 3;
  widows: 3;

  @if $paragraph-indent {
    &:not(.lede) + p {
      text-indent: 1em;
    }
  }
}
