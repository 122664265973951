/* ==========================================================================
    SPLIT
   ========================================================================== */

.split {
  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 62.75%;
    text-align: center;
  }

}
