/* ==========================================================================
    PAGINATION
   ========================================================================== */

.pagination {
  line-height: 1;
  text-align: center;

  li {
    display: inline-block;
  }

  a {
    display: inline-block;
    padding: spacer($micro);
    text-decoration: none;
    vertical-align: middle;

    &:hover {
      background: $color-primary;
      color: $color-tertiary
    }
  }
}

.pagination__next,
.pagination__prev {
  color: $color-primary;

}

.pagination__nextpage {
  a::after {
    content: ' \00bb';
  }
}

.pagination__prevpage {
  a::before {
    content: '\00ab ';
  }
}

.pagination__current a {
  background: $color-primary;
  color: $color-tertiary;
}
