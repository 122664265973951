/* ==========================================================================
    IMAGES
   ========================================================================== */

/* Fluid images. */
img {
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;

  // Force the correct display of lazyloaded responsive images.
  &[data-sizes="auto"] {
    display: block;
    width: 100%;
  }
}

/* Don't ignore `width` and/or `height` attributes if they are present. */
[width],
[height] {
  max-width: none;
}
