/* ==========================================================================
    FORM INPUTS
   ========================================================================== */

label {

  // &--required {
  //   &::after {
  //     color: $color-danger;
  //     content: '*';
  //     display: inline;
  //   }
  // }
  //
  // &--error {
  //   color: $color-danger;
  // }

  .form__group--inline & {
    display: inline-block;
  }
}
