/* =============================================================================
    BACKGROUND REPEAT
   ============================================================================= */
.u_bgs {
  background-size: auto !important;

  &-cover {
    background-size: cover !important;
  }

  &-contain {
    background-size: contain !important;
  }

}
