
/* =============================================================================
    PADDING TRUMPS
    // scss-lint:disable ImportantRule
  ============================================================================= */
$padding-locations: (
  bottom,
  left,
  right,
  top
);
$padding-location-groups: (
  horizontal: (left, right),
  vertical: (bottom, top)
);

$padding-default: spacer();
$padding-sizes: (
  pico: spacer($pico),
  micro: spacer($micro),
  milli: spacer($milli),
  default: spacer(),
  kilo: spacer($kilo),
  mega: spacer($mega),
  giga: spacer($giga),
);

@mixin padding($size, $location: false) {
  $size: map-get($padding-sizes, $size);
  @if $location {
    @if $location == horizontal {
      padding-left: $size !important;
      padding-right: $size !important;
    } @elseif $location == vertical {
      padding-top: $size !important;
      padding-bottom: $size !important;
    } @else {
      padding-#{$location}: $size !important;
    }
  } @else {
    padding: $size !important;
  }
}

.u_p {
  padding: $padding-default !important;

  // Just all round padding
  @each $size, $spacer in $padding-sizes {
    &-#{$size} {
      padding: $spacer !important;
    }
  }

  &-off {
    padding: 0 !important;
  }

  @each $location in $padding-locations {
    &-#{$location} {
      padding-#{$location}: $padding-default !important;

      &-off {
        padding-#{$location}: 0 !important;
      }

      @each $size, $spacer in $padding-sizes {
        &-#{$size} {
          padding-#{$location}: $spacer !important;
        }
      }

    }
  }

  @each $location-group, $locations in $padding-location-groups {
    &-#{$location-group} {
      @each $location in $locations {
        padding-#{$location}: $padding-default !important;
      }

      @each $size, $spacer in $padding-sizes {
        &-#{$size} {
          @each $location in $locations {
            padding-#{$location}: $spacer !important;
          }
        }
      }

    }
  }
}
