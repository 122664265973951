/* ==========================================================================
    FORMS
   ========================================================================== */

/* Standardise font rendering */
button,
input,
select,
textarea {
  font: inherit;
}

label {
  @include font-size( down( 2 ) );
  @include font-smoothing();
  display: block;
  font-weight: 700;
  max-width: 100%;
  opacity: .75;
}

progress,
meter {
  display: block;
  width: 100%;
  height: spacer();
}

/* Allow only vertical resizing of textareas. */
textarea {
  display: block;
  height: auto;
  resize: vertical;
  -webkit-appearance: none;
}

[type="email"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
textarea {
  @include font-size($root-size-em, 0, null);
  @include transition(all .3s ease);
  background-color: $color-background-input;
  border: 1px solid $color-border;
  border-radius: $radius-input;
  font-family: inherit;
  padding: spacer($pico);
  width: 100%;

  @if $opentype == true {
    // Turns on lining, proportional numerals without clarified zeroes
    font-feature-settings: 'kern' 0, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 0, 'lnum' 1, 'zero' 0;
  }

  &:focus {
    border-color: $color-info;
  }
}

/* De-bork search fields */
[type="search"] {
  -webkit-appearance: textfield;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

[type="radio"],
[type="checkbox"] {
  display: inline-block;
  margin-right: .5em;
}

/* Make range inputs behave like textual form controls. */
[type="range"] {
  display: block;
  width: 100%;
}

/* Fix cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
