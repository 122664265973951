/* ==========================================================================
    SERVICE
   ========================================================================== */

.service {

  [grid] {
    justify-content: center;

    @include mq( $from: mobileLandscape ) {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__image {
    flex-shrink: 0;
    width: 40%;

    @include mq( $from: mobileLandscape ) {
      width: 22%;
    }

    @include mq( $from: tablet, $until: desktop ) {
      display: none;
    }
  }

  &__image + &__content {
    flex-shrink: 0;
    width: 100%;

    @include mq( $from: mobileLandscape ) {
      flex-shrink: 1;
      padding-left: spacer( );
    }

    @include mq( $until: mobileLandscape ) {
      margin-top: spacer( );
    }

    @include mq( $from: tablet, $until: desktop ) {
      flex-shrink: 0;
      padding-left: 0;
    }

    @include mq( $from: desktop ) {
      flex-shrink: 1;
      padding-left: spacer( );
    }
  }

  &__content {
    flex-shrink: 1;
  }

  &__link {
    color: transparentize( palette( monochrome, 'white' ), .5 );

    &:hover {
      color: palette( monochrome, 'white' );
    }
  }
}
