/* ==========================================================================
    ANCHORS
   ========================================================================== */

a {
  @include transition(color .3s ease);
  color: $color-link;

  // Create better-looking links in browsers that support it.
  @supports (text-decoration-skip: ink) {
    text-decoration-color: inherit;
    text-decoration-skip: ink;
  }

  &:active,
  &.active {
    color: $color-link--active;
  }

  &:hover,
  &:focus {
    color: $color-link--hover;
  }
}
