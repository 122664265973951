/* ==========================================================================
    PAGINATION
   ========================================================================== */
.pagination {

  &__current a,
  a:hover {
    color: palette( 'monochrome', 'darker' );
    background-color: palette( 'monochrome', 'lighter' );
  }

  &__prevpage a::before {
    content: '\2190 ';
  }

  &__nextpage a::after {
    content: ' \2192';
  }
}
