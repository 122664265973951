.category-intro {
  position: relative;

  .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    height: 3px;
  }
}
