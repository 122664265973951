/* ==========================================================================
    BACKGROUND COLOR
   ========================================================================== */

.u_bgc {
  background-color: palette('monochrome', 'lighter') !important;

  &-current {
    background-color: currentColor !important;
  }

  // Our brand palette colours
  @if ($palettes) {
    @each $group, $colours in $palettes {
      @each $colourName, $colourValue in $colours {

        &-#{$group + '-' + $colourName} {
          background-color: map-get( $colourValue, 'colour' ) !important;
        }
      }
    }
  }
}
