/* ==========================================================================
    PAGER (ARTICLE NAV)
   ========================================================================== */

%pager-pseudo {
  background: $color-secondary;
  color: $color-background;
  display: inline-block;
  font-weight: $weight-strong;
  padding: spacer($micro);
  text-align: center;
  width: spacer($kilo);
}

.pager {

  &__next,
  &__previous {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__next {

    &:hover &::after {
      background: $color-secondary;
    }

    &::after {
      @extend %pager-pseudo;

      content: '\00bb';
      margin-left: spacer($milli);
    }
  }

  &__previous {

    &:hover &::before {
      background: $color-secondary;
    }

    &::before {
      @extend %pager-pseudo;

      content: '\00ab';
      margin-right: spacer($milli);
    }
  }
}
