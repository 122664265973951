/* ==========================================================================
    Float
   ========================================================================== */

.u_float {
  float: none !important;

  &-left {
    float: left !important;
  }

  &-right {
    float: right !important;
  }
}
