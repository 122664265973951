/* ==========================================================================
    FLEX SIZE - For use with RAGrid
    ----
    This is setup to use
    flex-basis: auto;
    width: {width value};

    This is because of the way IE 10 and 11 break the box model when using
    flex-basis with a value other that auto.
   ========================================================================== */

@mixin flex-size( $namespace: '' ) {
  $sizes: (
    '1/1': 100%,
    '1/2': ( 100% / 2 ),
    '1/3': ( 100% / 3 ),
    '2/3': ( ( 100% / 3 ) * 2 ),
    '1/4': ( 100% / 4 ),
    '3/4': ( ( 100% / 4 ) * 3 ),
    '1/5': ( 100% / 5 ),
    '2/5': ( ( 100% / 5 ) * 2 ),
    '3/5': ( ( 100% / 5 ) * 3 ),
    '4/5': ( ( 100% / 5 ) * 4 ),
    '1/6': ( 100% / 6 ),
    '5/6': ( ( 100% / 6 ) * 5 ),
    '1/7': ( 100% / 7 ),
    '2/7': ( ( 100% / 7 ) * 2 ),
    '1/8': ( 100% / 8 ),
    '1/9': ( 100% / 9 ),
    '1/10': ( 100% / 10 ),
    '1/11': ( 100% / 11 ),
    '1/12': ( 100% / 12 ),
  );

  $namespace-prefix: if( $namespace != '', $namespace + '-', '' );
  @each $size, $value in $sizes {
    [#{$namespace-prefix}flex-size~="#{$size}"] {
      @if $namespace != '' {
        @include mq( $from: $namespace ) {
          flex-basis: auto;
          width: $value;
        }
      } @else {
        flex-basis: auto;
        width: $value;
      }
    }

  }

}

@include flex-size( );
@include flex-size( mobile );
@include flex-size( mobileLandscape );
@include flex-size( tablet );
@include flex-size( desktop );

[grid] {
  &[tablet-order~="reverse"] {
    @include mq($from: tablet) {
      flex-direction: row-reverse;
    }
  }
}

.flex-1 {
  flex: 1;
}

.justify-between {
  justify-content: space-between;
}

.items-start {
  align-items: flex-start;
}
