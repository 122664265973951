
/* ==========================================================================
    LINE-HEIGHT TRUMPS
    // scss-lint:disable ImportantRule
   ========================================================================== */

.u_lh {

  &-inherit {
    line-height: inherit !important;
  }

  &-initial {
    line-height: initial !important;
  }

  &-normal {
    line-height: normal !important;
  }

  &-one {
    line-height: 1 !important;
  }

  &-loose {
    line-height: 1.55;
  }
}
