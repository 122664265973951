/* ==========================================================================
    COPY
   ========================================================================== */

.copy {
  .pages & {
    address,
    blockquote,
    h1, h2, h3, h4, h5, h6,
    p,
    dl, ol, ul,
    table {
      margin-left: auto;
      margin-right: auto;
    }
  }

}
