/* ==========================================================================
    TABLES
   ========================================================================== */

table {
  border-collapse: collapse;
  width: 100%;

  @if $opentype == true {
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 0;
  }
}

thead {
  border-bottom: 1px solid $color-border;
}

th {
//  font-weight: $weight-root;
}

th,
td {
  padding: spacer($micro);
  text-align: left;
}

tbody,
caption {
  @if $opentype == true {
    // Turns on tabular, lining numerals and slashed zero
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1, 'pnum' 0, 'tnum' 1, 'onum' 0, 'lnum' 1, 'zero' 1;
  }
}
