/* ==========================================================================
    QUOTES
   ========================================================================== */

/* BLOCK QUOTES */
blockquote {
  border-left: 3px solid $color-primary;
  clear: both;
  padding: 0 spacer();

  &::before,
  &::after {
    content: '';
  }
}

/* CITATIONS */
cite {
  display: block;
  font-style: normal;
}

.testimonial-og cite {
  @include font-size( down( 1 ), 1 );
  display: block;
  font-weight: $weight-strong;
  font-style: italic;

  &::before {
    color: currentColor;
    content: '~';
    margin-right: .25em;
  }
}

/* INLINE QUOTES */
q {
  quotes: '\2018' '\2019';

  &::before {
    content: '\2018';
  }

  &::after {
    content: '\2019';
  }
}
