/* ==========================================================================
    SR (Screen Reader)
   ========================================================================== */

/* Hide an element but leave it available to speaking browsers. */
.u_sr {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  // Make visuallyhidden elements focusable with a keyboard.
  &-focusable {

    &:active,
    &:focus {
      position: static;
      clip: auto;
      height: auto;
      width: auto;
      margin: 0;
      overflow: visible;
    }
  }
}
