
/* ==========================================================================
    TEXT CASE CONTROL STYLES
   ========================================================================== */

.u_tt {
  text-transform: none !important;

  &-titlecase {
    text-transform: capitalize !important;
  }

  &-uppercase {
    text-transform: uppercase !important;
  }

  &-capitalize {
    text-transform: capitalize !important;
  }

}
