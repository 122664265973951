
/* ==========================================================================
    FONT SIZING
    An easier way to match type sizes defined in /generic/modular-scale
    // scss-lint:disable ImportantRule
   ========================================================================== */
.u_fs {
  @include font-size( $root-size-em, null, null, !important );

  &-up5 {
    @include font-size( up( 5 ), null, null, !important );
  }

  &-up4 {
    @include font-size( up( 4 ), null, null, !important );
  }

  &-up3 {
    @include font-size( up( 3 ), null, null, !important );
  }

  &-up2 {
    @include font-size( up( 2 ), null, null, !important );
  }

  &-up1 {
    @include font-size( up( 1 ), null, null, !important );
  }

  &-down1 {
    @include font-size( down( 1 ), null, null, !important );
  }

  &-down2 {
    @include font-size( down( 2 ), null, null, !important );
  }

  &-down3 {
    @include font-size( down( 3 ), null, null, !important );
  }

  &-down4 {
    @include font-size( down( 4 ), null, null, !important );
  }

  &-small {
    @extend %small; // Match the <small> element size.
  }
}
