/* ==========================================================================
    FORMS - RADIO & CHECKBOX STYLES
   ========================================================================== */

.control {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1; /* Put the input behind the label so it doesn't overlay text */

    // Focus
    &:focus ~ .control-indicator {
      box-shadow: 0 0 0 .075em $color-background, 0 0 0 .2rem $color-primary;
    }

    // Checked state
    &:checked ~ .control-indicator {
      background-color: $color-background;
    }

    // Active
    &:active ~ .control-indicator {
      background-color: transparentize($color-secondary, .8);
    }
  }
}

.control-indicator {
  border: 2px solid $color-copy;
  position: absolute;
  left: 0;
  display: block;
  width: spacer($milli);
  height: spacer($milli);
  line-height: 1;
  text-align: center;
  background-size: down( 1 );
  background-position: center center;
  background-repeat: no-repeat;
  user-select: none;
}



/* Checkbox modifiers */
.checkbox input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNjcxIDU2NnEwIDQwLTI4IDY4bC03MjQgNzI0LTEzNiAxMzZxLTI4IDI4LTY4IDI4dC02OC0yOGwtMTM2LTEzNi0zNjItMzYycS0yOC0yOC0yOC02OHQyOC02OGwxMzYtMTM2cTI4LTI4IDY4LTI4dDY4IDI4bDI5NCAyOTUgNjU2LTY1N3EyOC0yOCA2OC0yOHQ2OCAyOGwxMzYgMTM2cTI4IDI4IDI4IDY4eiIvPjwvc3ZnPg==);
}

/* Radio modifiers */
.radio .control-indicator {
  border-radius: 50%;
}

.radio input:checked ~ .control-indicator {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPiAgPHBhdGggZD0iTTE2NjQgODk2cTAgMjA5LTEwMyAzODUuNXQtMjc5LjUgMjc5LjUtMzg1LjUgMTAzLTM4NS41LTEwMy0yNzkuNS0yNzkuNS0xMDMtMzg1LjUgMTAzLTM4NS41IDI3OS41LTI3OS41IDM4NS41LTEwMyAzODUuNSAxMDMgMjc5LjUgMjc5LjUgMTAzIDM4NS41eiIgZmlsbD0iI2Y2ZDMzNyIvPjwvc3ZnPg==);
}
