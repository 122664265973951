/* ==========================================================================
    FAQS
   ========================================================================== */

.faqs {

  &__toc ul {
    // list-style: inherit;

    li {
      border-left: $width-border-small solid palette( 'green', 'light' );
      margin-top: spacer( $pico );
      padding-left: spacer( $micro );
      width: 100%;
    }
  }

  dt,
  dd {
    border-left: $width-border solid palette( 'green', 'light' );
  }
}
