/* ==========================================================================
    TESTIMONIALS
   ========================================================================== */

$testimonial-border-colour: palette( 'green', 'light' );

.cs-testimonial {

    &:nth-of-type(n+2) {
        padding-top: 2rem;
    }

    .copy {
        padding-bottom: 1.5rem;
        font-size: 1.5rem;
    }
}

.testimonial-og {

  &__copy {
    background: palette( monochrome, 'lighter' );
    border-bottom: $width-border solid $testimonial-border-colour;
    border-radius: 0;

    &::after {
      @include triangle( down, $root-size-em, $testimonial-border-colour );
      border-width: 18px 0 0 18px;
      margin-top: #{$width-border};
      top: 100%;
    }
  }

  cite::before {
    display: none;
  }
}


.testimonial {

  background: palette( monochrome, 'lighter' );
  border-bottom: $width-border solid $testimonial-border-colour;
  border-radius: 0;

  cite::before {
    display: none;
  }
}

.testimonials {
  blockquote + * {
    margin-top: 0;
  }
}

.testimonial-border {
  border-top: 16px solid #00AFA9;
  position: relative;
}

.testimonial-border::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1.5em solid #00AFA9;
  border-width: 40px 0 0 40px;
  top: 0;
  left: 15%;
}

.testimonial-border-bottom {
  border-bottom: 16px solid #00AFA9;
  position: relative;
  z-index: 5;
}

.testimonial-border-bottom::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1.5em solid #00AFA9;
  border-width: 40px 0 0 40px;
  bottom: -50px;
  left: 15%;
}

.star {
  height: 26px;
  width: 26px;

  &--disabled {
    opacity: 0.3;
  }
}
