.card {
  background-color: palette( 'monochrome', 'lighter' );

  &--styled {
    border: 0;
    border-bottom: $width-border-small solid palette( 'green', 'light' );
  }

  &--styled-alt {

    @include mq($from: tablet) {
      border-bottom: $width-border solid palette( 'green', 'normal' );
    }
    .card__author {
      max-width: 60px;
    }
  }
}
