/* ==========================================================================
    CARD
   ========================================================================== */

.card {
  background: $color-background;
  border: 1px solid;
  border-color: $color-border opacify($color-border, .05) opacify($color-border, .075);

  &__content {
    padding: spacer();
  }

  &__img {
    border-bottom: 1px solid $color-border;
  }
}
