/* =============================================================================
  Template:   JBGass by WebDNA
  Authors:    Nathanial Hammond - @nfourtythree and Seth Warburton - @nternetinspired
  Version:    1.1
  Created:    April 2017
  Copyright:  WebDNA Ltd. - ©2017. All rights reserved
============================================================================= */

// scss-lint:disable SpaceAfterVariableName

// Heads-up!
//
// Certain variables have a default value of 'null' so that there is no output
// for any rule using that variable name. This is deliberate, because in many
// cases the output would be identical to the browser calculated value, i.e.
// unnecessary css, and what's the point in that!
//
// For example, if your headings are supposed to be the same colour as the body
// text then you don't need to set the heading colour, it already is that colour.
// If you want your heading to be a different colour, then go ahead and change
// the $color-headings variable to whatever you want. If it isn't null then the
// colour rule will be compiled to your css.
//
// Bottom line? Writing code you don't have to is a mug's game.

/* ROOT VARIABLES
============================================================================= */

// Default font-size. Set in px, it will be converted to em.
$root-size                   : 18 !default;
// Converts the $root-size value to EMs for use in the functions below.
$root-size-em                : ($root-size / 16) * 1em;

// Default lead. A unitless value, adjust to taste.
$root-lineheight             : 1.5 !default;


/* FONT STACK VARIABLES
============================================================================= */

// Our font stacks
$font-system                 : system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !default;
$font-sans                   : sans-serif !default;
$font-serif                  : serif !default;
$font-code                   : monospace !default;
$font-ampersand              : Baskerville, 'Goudy Old Style', Palatino, 'Book Antiqua', serif;

// Project element stacks - Only loaded with the fontsLoaded event, otherwise we'll default to a system font first. See /elements/_body.scss
$font-root                   : 'Helvetica Neue', sans-serif !default;
$font-button                 : null !default;
$font-heading                : null !default;


/* =============================================================================
    WEB FONTS
============================================================================= */

//////////////////////////////////////////////////////////////////////
// PRO-TIP: If you are using Google fonts you can grab font-files   //
// and CSS from: https://google-webfonts-helper.herokuapp.com/fonts //
//////////////////////////////////////////////////////////////////////


/* FONT WEIGHT VARIABLES
============================================================================= */

// Button weight
$weight-button               : 700 !default;

// Heading weight
$weight-heading              : 700 !default;

// Default font-weight.
$weight-root                 : 400 !default;

// The weight used for <b> and <strong> elements.
$weight-strong               : 700 !default;


/* TYPOGRAPHIC VARIABLES
============================================================================= */
// A modular typographic scale ensures harmonious font sizing, and saves us from
// simply pulling magic numbers out of a hat! See modularscale.com/ for details.
$minor-second                : 1.067;
$major-second                : 1.125;
$minor-third                 : 1.2;
$major-third                 : 1.25; // This is the default in most browsers.
$perfect-fourth              : 1.333;
$augmented-fourth            : 1.414;
$perfect-fifth               : 1.5;
$minor-sixth                 : 1.6;
$golden-section              : 1.618;
$major-sixth                 : 1.667;
$minor-seventh               : 1.778;
$major-seventh               : 1.875;
$octave                      : 2;
$major-tenth                 : 2.5;
$major-eleventh              : 2.667;
$major-twelfth               : 3;
$double-octave               : 4;

// Our chosen scale factor for font sizing.
$scale                       : $major-third !default;



// A visual guide to help correct a wonky vertical rhythym. WIP!!
$show-baseline               : false !default;
$font-capheight              : .72;
// Calculate the offset required to correctly align the baseline guide
$baseline-distance           : ($root-lineheight - ($font-capheight * .5)) * 1em;

// Enable OpenType font features (If supported by the font-family.)?
// Inspired by github.com/kennethormandy/normalize-opentype.css
$opentype                    : true !default;

// Paragraph settings.
$paragraph-indent            : false !default; // Get old-skool classy?
$paragraph-justify           : false !default;

// Default quote marks.
$open-quote                  : \201C !default; // If you're French, change these.
$close-quote                 : \201D !default;

// Default letter spacing and alt
$letter-spacing              : .075em !default;


/* COLOUR VARIABLES
============================================================================= */

// Basic project colours
$color-copy                  : #585858 !default;
$color-headings              : #000000 !default;

// Additional project colors
$color-background            : null !default;
$color-background-input      : null !default;
$color-border                : rgba( 0,0,0,.1 ) !default;

// Our primary color pallette
$color-primary               : #c6c6c5 !default;
$color-secondary             : #e5e5e5 !default;
$color-tertiary              : #f5f5f5 !default;

// Contextual Colours
$color-danger                : #f44336 !default;
$color-info                  : #2196f3 !default;
$color-success               : #4caf50 !default;
$color-warning               : #ffc107 !default;

// Link colours.
$color-link                  : null !default;
$color-link--active          : null !default;
$color-link--hover           : null !default;
$color-link--visited         : null !default;


/* SHAPE(?) VARIABLES - $TODO: Name this section better.
============================================================================= */

// The default size of rounded corners.
$radius-brand                : .25em !default;
$radius-button               : .25em !default;
$radius-input                : .125em !default;


/* WIDTH & BREAKPOINT VARIABLES
============================================================================= */

// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    mobile:  320px,
    tablet:  740px,
    desktop: 980px,
    wide:    1300px,

    // Tweakpoints
    desktopAd: 810px,
    mobileLandscape: 480px
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide);


// Be nice to readers and constrain copy width to maintain an optimal measure.
$width-measure               : 45em !default; // ~66 characters.
$width-measure-narrow        : 30em !default; // ~45 characters.
$width-measure-middle        : 36em !default;
$width-measure-wide          : 52em !default; // ~80 characters.


// Optionally constrain site content width.
$width-container             : 75em !default;
$width-container-max         : 90em !default;
$width-container-narrow      : 45em !default;

$width-gutter                : spacer(.5);


/* SPACING VARIABLES
============================================================================= */

// Multiplication factors for the spacer() function `.generic/_functions.scss`,
// Where 1 == the default vertical rythym unit, i.e.: $root-lineheight * em.

$nano: .125;
$pico: .25;
$micro: .5;
$milli: .75;

$kilo: 2;
$mega: 4;
$giga: 6;
$terra: 8;

/* Color Palettes
============================================================================= */
$palettes: (
  'monochrome': (
  'black': ( colour: #000000, hover: #585858 ),
  'darker':  ( colour: #585858, hover: #B7BFBF ),
  'dark':  ( colour: #B7BFBF, hover: #D2D7D7 ),
  'grey':  ( colour: #D2D7D7, hover: #758283 ),
  'light': ( colour: #E0E3E3, hover: #D2D7D7 ),
  'lighter': ( colour: #F3F3F3, hover: #D2D7D7 ),
  'white': ( colour: #FCFCFC, hover: #D2D7D7 )
  )
);
