/* ==========================================================================
    CENTER
   ========================================================================== */

.u_center {
  @include center;

  &-x {
    @include center-h;
  }

  &-y {
    @include center-v;
  }
}
