
/* ==========================================================================
    TEXT ALIGNMENT CONTROL STYLES
    // scss-lint:disable ImportantRule
   ========================================================================== */
.u_ta {
  text-align: inherit;

  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }
}
