/* ==========================================================================
    DROPCAPS
   ========================================================================== */

.dropcap,
%dropcap {
  @supports (initial-letter: 3) or (-webkit-initial-letter: 3) {
    &::first-letter {
      font-weight: $weight-strong;
      margin-right: 0.5em;
      -webkit-initial-letter: 3;
      initial-letter: 3;
    }
  }
}
