/* ==========================================================================
    CUSTOM LIST STYLE
   ========================================================================== */


/* Styles unordered lists - bullets */
.styled-lists {
  ul {
    list-style-type: none;

    li {
      position: relative;

      &::before {
        content: "\2713";
        color: palette( 'green', 'light' );
        margin-right: 1em;
        position: absolute;
        left: -1.5em;
      }
    }
  }
}

.tick-list {
  ul {
    list-style-type: none;
    margin-left: 1.8em;

    li {
      position: relative;
      padding-bottom: 1rem;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        background-image: url('/assets/images/tick.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0.3em;
        left: -1.8em;
      }
    }
  }
}

