/* ==========================================================================
    HERO
   ==========================================================================*/

.hero {

  .hero__title,
  .hero__subtitle {
    color: inherit;
  }

  .blog &,
  .news & {
    .hero__title {

      @include font-size( up( 5 ), null, 1 );

      @include mq( $from: tablet ) {
        @include font-size( up( 7 ), null, 1 );
      }

    }
  }
}
