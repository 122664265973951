
/* =============================================================================
    LETTER SPACING
   ============================================================================= */

.u_letterspace {
  letter-spacing: $letter-spacing;

  &-normal {
    letter-spacing: normal;
  }
}
