/* ==========================================================================
    TABLES
   ========================================================================== */

.table-bordered {

  border: 1px solid $color-border;

  th {
    background-color: $color-tertiary;
    border-bottom: 3px solid $color-border;
  }

  tr {
    border-bottom: 1px solid $color-border;
  }

}

.table-split {
  td {
    &:last-of-type {
      text-align: right;
    }
  }
}

.table-striped {
  border-top: 1px solid $color-border;

  thead {
    background-color: $color-tertiary;
  }

  tbody {

    td {

    }
    tr {
      border-bottom: 1px solid $color-border;
      &:nth-of-type(even) {
        background-color: $color-tertiary;

      }
    }
  }
}
