/* ==========================================================================
    LISTS
   ========================================================================== */

.list {

  &-inline {
    margin-left: 0;

    li {
      display: inline-block;
    }
  }

  &-styled {

    li {
      padding: spacer($micro);

      + li {
        border-top: 1px solid $color-border;
      }
    }
  }

  &-unstyled {
    margin-left: 0;
    list-style: none;
  }
}
