/* =============================================================================
    BASE ICON STYLES
============================================================================= */

[class*="icon-"],
[class^=" icon-"] {
  background-size: contain;
  fill: palette( monochrome, 'grey' );
  display: inline-block;
  vertical-align: middle;
  height: up( 4 );
  width: up( 4 );

  &.icon--milli {
    height: spacer($milli);
    width: spacer($milli);
  }

  &.icon--kilo {
    height: spacer($kilo);
    width: spacer($kilo);
  }

  &.icon--mega {
    height: spacer($mega);
    width: spacer($mega);
  }

}
