/* ==========================================================================
    CENTER
   ========================================================================== */
   
// Vertically and horizontally center something of unknown size inside and parent of unknown size.
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// Vertically and horizontally center something of unknown size inside and parent of unknown size.
@mixin center-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
