/* ==========================================================================
    RULES
   ========================================================================== */

hr {
  background: $color-border;
  border: 0;
  display: block;
  height: 1px;
  line-height: ceil($root-size-em / $root-lineheight) * ($root-lineheight / $root-size-em);
}
