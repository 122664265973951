/* ==========================================================================
    MOBILE TOGGLE
   ========================================================================== */

.mobile_toggle {
  background-color: palette( 'monochrome', 'white' );
  border-radius: $radius-button;

  &:hover {
    background-color: palette( 'monochrome', 'lighter' );
  }

  &--transparent {
    background-color: transparent;
  }

  svg {
    fill: palette( 'monochrome', 'darker' );
  }
}

.hero--dark {
  .mobile_toggle {
    svg {
      fill: palette( 'blue', 'normal' );
    }
  }
}
