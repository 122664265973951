/* ==========================================================================
    PALETTE
   ========================================================================== */

$angle: 20deg;
$reverse-angle: 160deg;

.palette_bgc {
  @include background-cut(palette('blue', lighter), palette('blue', light), $angle);

  &-blue {
    @include background-cut(palette('blue', lighter), palette('blue', light), $angle);
    color: #fff;

    &-r {
      @include background-cut(palette('blue', lighter), palette('blue', light), $reverse-angle);
      color: #fff;
    }

    &-flip {
      @include background-cut(palette('blue', light), palette('blue', lighter), $angle);
      color: #fff;

      &-r {
        @include background-cut(palette('blue', light), palette('blue', lighter), $reverse-angle);
        color: #fff;
      }
    }

  }

  &-grey {
    @include background-cut( palette( 'monochrome', 'light' ), palette( 'monochrome', 'lighter' ), $angle );

    &-r {
      @include background-cut( palette( 'monochrome', 'light' ), palette( 'monochrome', 'lighter' ), $reverse-angle );
    }

    &-flip {
      @include background-cut( palette( 'monochrome', 'lighter' ), palette( 'monochrome', 'light' ), $angle );

    }

    &-flip-r {
      @include background-cut( palette( 'monochrome', 'lighter' ), palette( 'monochrome', 'light' ), $reverse-angle );
    }


  }

  // &-green {
  //   @include background-cut(palette(green, light), palette(green, normal), $angle);
  //   color: #fff;
  //
  //   &-r {
  //     @include background-cut(palette(green, light), palette(green, normal), $reverse-angle);
  //     color: #fff;
  //   }
  //
  //   &-flip {
  //     @include background-cut(palette(green, normal), palette(green, light), $angle);
  //     color: #fff;
  //
  //     &-r {
  //       @include background-cut(palette(green, normal), palette(green, light), $reverse-angle);
  //       color: #fff;
  //     }
  //   }
  //
  // }
}

.palette_b {

  &-green {
    border-color: palette( 'green', 'light' );
    border-style: solid;
    border-width: $width-border;
  }

  &-grey {
    border-color: palette( 'monochrome', 'grey' );
    border-style: solid;
    border-width: $width-border;
  }

  &--small {
    border-width: $width-border-small;
  }

  &--left {
    border-bottom-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }

  &--right {
    border-bottom-width: 0;
    border-left-width: 0;
    border-top-width: 0;
  }

  &--top {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  &--bottom {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }

}
