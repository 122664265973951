
.contact-form {

  &__background {
  //  background-size: cover;
    background-repeat: repeat-x;
    border-width: $width-border !important;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.075) inset;
    position: absolute;
    height: 56%;
    top: 22%;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .card {
//  box-shadow: 0 1px 15px 1px rgba(0,0,0,.05);
    margin: auto;
    max-width: 30em;
  }

  .form__field {
    max-width: none;
  }

  [grid][padded] {
    margin: 0;

    &,
    > * {
      padding: spacer( .25 );
    }
  }
}

.contact-form_sidebar {
  fieldset {
    padding: 0 !important;
  }

  .form__row + .form__row {
    margin-top: spacer( $micro );
  }
}

.fui-form-container {
  margin-top: spacer();
}

.fui-submit {
  font-size: 20px !important;
  text-transform: uppercase;
  --fui-submit-btn-bg-color: #003562;
  --fui-submit-btn-border-color: #003562;
  --fui-submit-btn-bg-color-hover: #00427C;
  --fui-submit-btn-border-color-hover: #00427C;
  --fui-submit-btn-box-shadow-focus: 0 0 0 0.25rem rgb(0, 53, 98, 0.5);
}

.fui-group {
  background: palette( monochrome, 'lighter' );
}

.fui-group .fui-field-rows .fui-row:not(:last-child) {
  border-bottom: solid 1px palette( monochrome, 'grey' ) !important;
  margin-bottom: spacer();
}

.fui-group .fui-field-rows .fui-row .fui-row {
  border-bottom: 0 !important;
  margin-bottom: 0;
}

.fui-group .fui-legend,
.fui-group .fui-label {
  font-weight: bold;
  opacity: 1;
}

.fui-group .fui-field-rows .fui-row .fui-row .fui-label {
  font-weight: normal;
  @include font-size(down(2));
}

.fui-group .fui-instructions {
  @include font-size(down(1));
  color: palette( monochrome, 'darker' );
}

.form-block {

  .fui-label,
  .fui-legend,
  .fui-checkbox-label {
    font-weight: normal;
    opacity: 1;
    @include font-size(up(1));
  }

  .fui-input {
    background: none;
    border: 0;
    border-radius: 0;
    border-bottom: solid 1px palette( monochrome, 'darker' );
    box-shadow: 0;
  }

  .fui-submit {
    --fui-submit-btn-bg-color: #009590;
    --fui-submit-btn-border-color: #009590;
    --fui-submit-btn-bg-color-hover: #007c77;
    --fui-submit-btn-border-color-hover: #007C77;
    --fui-submit-btn-box-shadow-focus: 0 0 0 0.25rem rgba(0, 149, 144, 0.5);
  }

}

.newsletter-form {
  .fui-label,
  .fui-legend,
  .fui-checkbox-label {
    font-weight: normal;
    opacity: 1;
    @include font-size(up(1));
  }

  .fui-input {
    background: white;
    border: 0;
    border-radius: 0;
    box-shadow: 0;
  }
}

.u_c-monochrome-white {
  --fui-label-color: #fff;
}
