/* ==========================================================================
    TESTIMONIALS
   ========================================================================== */

.testimonial {
  border: 0;
  padding: spacer(1.4);
  padding-bottom: spacer(6.5);
  position: relative;
}

.testimonial-og {
  border: 0;
  padding: 0;

  &__copy {
    border-radius: $radius-brand;
    padding: spacer();
    position: relative;

    &::after {

    }
  }
}
