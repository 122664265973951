/* ==========================================================================
    SITE FOOTER
   ========================================================================== */

[role="contentinfo"] {
  @include mq( $until: tablet ) {
    .is-parent {
      ~ .is-parent {
        margin-top: spacer( );
      }
    }
  }

  .nav {
    li {
      a {
        padding: 0;
      }
    }
  }
}

.footer-certs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem -1rem;
}

.footer-certs .logo {
    padding: 1rem;
}

.footer-certs .sra {
    width: 240px;
    height: 142px;
}

.footer-certs .sra .wrapper {
    position: relative;
    padding-bottom: 59.1%;
    height: auto;
    overflow: hidden;
}
