/* ==========================================================================
    SITE LOGO
    ---

    <div class="logo overlay" itemscope itemtype="http://schema.org/Organization">
      <a class="overlay__link" itemprop="url" href="http://www.example.com/">Home</a>
      <img itemprop="logo" src="http://www.example.com/logo.png" />
    </div>
   ========================================================================== */

.logo {

  &__img {
    height: 100px;
  }
}
