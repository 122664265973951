/* ==========================================================================
    BREADCRUMBS
   ========================================================================== */

.breadcrumbs {

  li {

    &:not(:last-child)::after {
      //content: '\00BB' '\00A0'; // Chevron style
      content: '\002F' '\00A0'; // Path style
      margin-left: .5em; // magic number tehehehehe
    }
  }
}
