/* ==========================================================================
    TABBED CONTENT
   ========================================================================== */

.tabs {

  position: relative;

  &__panel {
    background-color: $color-background;
    position: absolute;
  }

  &__panels {
    position: relative;
  }

  .tab {

    &[aria-selected="true"] {
      background-color: $color-copy;
      color: $color-background;
    }
  }

  [role="tabpanel"] {
    background-color: $color-background;

  //  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &[aria-hidden="true"] {
      display: none;
    }
  }
}
